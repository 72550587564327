import PropTypes from 'prop-types'
import React from 'react'
import { IntlProvider } from 'react-intl'
import { connect } from 'react-redux'

import messages from '../../assets/locales'

const L10nProvider = ({ language, children }) => {
  const res = language.split('-')
  const locale = res[0]

  return (
    <div lang={`${locale}`}>
      <IntlProvider locale={locale} messages={messages[language]}>
        {children}
      </IntlProvider>
    </div>
  )
}

L10nProvider.propTypes = {
  children: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
}

const mapStateToProps = (state, ownProps) => ({
  language: state.system.language,
  children: ownProps.children,
})

export default connect(mapStateToProps, null)(L10nProvider)
