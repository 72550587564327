import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import {
  Nav,
  NavItem,
  NavbarBrand,
  NavbarToggler,
  UncontrolledTooltip,
} from 'reactstrap'

import L10nFormattedMessage from '../../L10nFormattedMessage'

import { logout } from '../../../actions/user'

import Logo2 from '../../../assets/img/logo2.svg'
import LogoBlueConnect from '../../../assets/img/logo-blue-connect.svg'

class Header extends Component {
  shouldComponentUpdate(nextProps) {
    const { info } = this.props
    return nextProps.info.cloudDevice !== info.cloudDevice
  }

  mobileSidebarToggle = (event) => {
    event.preventDefault()
    document.body.classList.toggle('sidebar-mobile-show')
  }

  handleLogout = () => {
    const { dispatch } = this.props
    return dispatch(logout())
  }

  render() {
    const { info } = this.props

    return (
      <header className="app-header navbar">
        <NavbarBrand
          tag={() => (
            <NavLink
              automation-id="navlink-header-logo"
              className="navbar-brand"
              style={{ backgroundImage: info.cloudDevice ? `url(${LogoBlueConnect})` : `url(${Logo2})` }}
              to="/"
            />
          )}
        />
        <NavbarToggler className="d-lg-none" onClick={this.mobileSidebarToggle} automation-id="toggle-header-sidebar">
          <i className="fas fa-bars" />
        </NavbarToggler>
        <Nav className="d-md-down-none" navbar>
          <NavItem className="px-3 h5" style={{ fontWeight: 'lighter' }}>
            {info.productName}
            {' - '}
            <L10nFormattedMessage id="common.servermanagement" />
          </NavItem>
        </Nav>
        <Nav className="ml-auto" navbar>
          <NavItem>
            <NavbarToggler
              id="logout"
              className="nav-link"
              onClick={this.handleLogout}
              automation-id="button-header-logout"
            >
              <i className="fas fa-sign-out-alt" />
            </NavbarToggler>
            <UncontrolledTooltip placement="bottom" target="logout">
              <L10nFormattedMessage id="layout.es.header.logout" />
            </UncontrolledTooltip>
          </NavItem>
        </Nav>
      </header>
    )
  }
}

Header.propTypes = {
  dispatch: PropTypes.func.isRequired,
  info: PropTypes.object.isRequired,
}

export default Header
