import * as types from '../constants/ActionTypes'

const accServer = (state = {
  info: {},
  license: {},
  logTypes: [],
  ports: {
    service: 38880,
    rtp: 51000,
  },
  serverRepairing: false,
  storageManagement: {},
  accLpr: {},
}, action) => {
  switch (action.type) {
    case types.ACC_SERVER_INFO:
      return { ...state, info: action.info }
    case types.ACC_SERVER_LICENSE:
      return { ...state, license: action.license }
    case types.ACC_SERVER_LOG_TYPES:
      return { ...state, logTypes: action.types }
    case types.ACC_SERVER_PORTS:
      return { ...state, ports: action.ports }
    case types.ACC_SERVER_REPAIRING:
      return { ...state, serverRepairing: action.repairing }
    case types.ACC_SERVER_STORAGE_MANAGEMENT:
      return { ...state, storageManagement: action.storageManagement }
    case types.ACC_SERVER_LPR:
      return { ...state, accLpr: action.accLpr }
    default:
      return state
  }
}

export default accServer
