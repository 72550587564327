const TOKEN_KEY = 'user.token'
const EXPIRES_AT_KEY = 'user.token.expires_at'

export default class Token {
  static get = () => {
    const token = window.localStorage.getItem(TOKEN_KEY)
    const expiresAt = window.localStorage.getItem(EXPIRES_AT_KEY)
    return expiresAt > Date.now() ? token : undefined
  }

  static getExpiresAt = () => (
    window.localStorage.getItem(EXPIRES_AT_KEY)
  )

  static set = (token, expiresIn) => {
    if (expiresIn > 0) {
      window.localStorage.setItem(TOKEN_KEY, token)
      window.localStorage.setItem(EXPIRES_AT_KEY, Date.now() + expiresIn)
    }
  }

  static remove = () => {
    window.localStorage.removeItem(TOKEN_KEY)
    window.localStorage.removeItem(EXPIRES_AT_KEY)
  }
}
