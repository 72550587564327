import PropTypes from 'prop-types'
import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

import CenterSpinner from '../../CenterSpinner'
import L10nFormattedMessage from '../../L10nFormattedMessage'

const LostConnectionModal = ({ modal }) => (
  <Modal className="modal-info" isOpen={modal}>
    <ModalHeader>
      <i className="fas fa-info-circle" />
      {' '}
      <L10nFormattedMessage id="layout.es.lostconnection" />
    </ModalHeader>
    <ModalBody>
      <CenterSpinner />
    </ModalBody>
  </Modal>
)

LostConnectionModal.propTypes = {
  modal: PropTypes.bool.isRequired,
}

export default LostConnectionModal
