import { isEmpty, now } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component, Suspense, lazy } from 'react'
import { Fade } from 'reactstrap'
import { connect } from 'react-redux'
import { Switch, Redirect, Route } from 'react-router-dom'

import Header from './Header'
import Sidebar from './Sidebar'
import Footer from './Footer'

import LostConnectionModal from '../../Modal/LostConnectionModal'

import { fetchPoe } from '../../../actions/network'
import { fetchServices, fetchTime, upgrade } from '../../../actions/system'
import { ping } from '../../../actions/webadmin'

import CenterSpinner from '../../CenterSpinner'

const About = lazy(() => import('../../../containers/About'))
const AccLog = lazy(() => import('../../../containers/Log/Acc'))
const AccServer = lazy(() => import('../../../containers/AccServer'))
const Dashboard = lazy(() => import('../../../containers/Dashboard'))
const Debug = lazy(() => import('../../../containers/Debug'))
const Device = lazy(() => import('../../../containers/Device'))
const DeviceLog = lazy(() => import('../../../containers/Log/Device'))
const Network = lazy(() => import('../../../containers/Network'))
const Poe = lazy(() => import('../../../containers/Poe'))
const Storage = lazy(() => import('../../../containers/Storage'))

class Es extends Component {
  constructor(props) {
    super(props)

    props.dispatch(ping())

    props.dispatch(fetchServices())
    props.dispatch(fetchPoe())

    const sendInterval = setInterval(() => this.sendPing(), 8000)
    const handleInterval = setInterval(() => this.handlePing(), 250)

    this.state = {
      lost: false,
      sendInterval,
      handleInterval,
    }
  }

  componentDidMount() {
    document.addEventListener('visibilitychange', this.pingOnVisible)
  }

  componentWillUnmount() {
    const { sendInterval, handleInterval } = this.state
    clearInterval(sendInterval)
    clearInterval(handleInterval)
    document.removeEventListener('visibilitychange', this.pingOnVisible)
  }

  pingOnVisible = () => {
    const { dispatch } = this.props
    if (document.visibilityState === 'visible') {
      dispatch(ping())
    }
  }

  sendPing = () => {
    const { dispatch, webadmin } = this.props
    if (webadmin.enablePing) {
      dispatch(ping())
    }
  }

  handlePing = () => {
    const { dispatch, system, webadmin } = this.props
    const { lost: lostState } = this.state

    if (webadmin.enablePing) {
      const lost = (now() - webadmin.lastPing > 15000) && webadmin.lastPingFailed
      if (lostState !== lost) {
        this.setState({ lost })

        if (system.upgrading && !lost) {
          dispatch(upgrade(false))
        } else if (!lost) {
          dispatch(fetchTime())
        }
      }
    }
  }

  render() {
    const {
      dispatch,
      location,
      network,
      system,
    } = this.props
    const { lost } = this.state

    return (
      <div className="app">
        <LostConnectionModal modal={lost} />
        {!isEmpty(system.info) && [
          <Header key="header" info={system.info} dispatch={dispatch} />,
          <div key="body" className="app-body">
            <Sidebar location={location} network={network} system={system} />
            <main className="main">
              <div className="container" style={{ padding: '1.875rem' }}>
                <Fade key={location.pathname}>
                  <Suspense fallback={<CenterSpinner />}>
                    <Switch>
                      <Route exact path="/dashboard" component={Dashboard} />
                      {system.info.cloudDevice ? ([
                        <Route key={1} exact path="/services" component={AccServer} />,
                        <Route key={2} exact path="/logs/services" component={AccLog} />,
                      ]) : ([
                        <Route key={1} exact path="/acc/server" component={AccServer} />,
                        <Route key={2} exact path="/acc/logs" component={AccLog} />,
                      ])}
                      <Route exact path="/network" component={Network} />
                      {network.poe && !isEmpty(network.poe.ports)
                        && <Route exact path="/poe" component={Poe} />}
                      {!system.info.storagelessDevice
                        && <Route exact path="/storage" component={Storage} />}
                      <Route exact path="/logs/device" component={DeviceLog} />
                      <Route exact path="/device" component={Device} />
                      <Route exact path="/about" component={About} />
                      <Route exact path="/debug" component={Debug} />
                      <Redirect from="/" to="/dashboard" />
                    </Switch>
                  </Suspense>
                </Fade>
              </div>
            </main>
          </div>,
          <Footer key="footer" />,
        ]}
      </div>
    )
  }
}

Es.propTypes = {
  dispatch: PropTypes.func.isRequired,
  network: PropTypes.object.isRequired,
  system: PropTypes.object.isRequired,
  webadmin: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  network: state.network,
  system: state.system,
  webadmin: state.webadmin,
})

export default connect(mapStateToProps)(Es)
