import PropTypes from 'prop-types'
import React from 'react'
import { Button, Spinner } from 'reactstrap'

import L10nFormattedMessage from '../../L10nFormattedMessage'

const ApplyButton = ({
  'automation-id': automationId,
  busy,
  disabled,
  icon,
  label,
  onClick,
}) => (
  <Button
    automation-id={automationId}
    className="float-right"
    color="info"
    disabled={disabled || busy}
    onClick={onClick}
    type="submit"
  >
    {busy ? (
      <Spinner color="light" size="sm" />
    ) : (
      icon !== undefined ? icon : <i className="fas fa-check" />
    )}
    {' '}
    <L10nFormattedMessage id={label || 'common.apply'} />
  </Button>
)

ApplyButton.propTypes = {
  'automation-id': PropTypes.string.isRequired,
  busy: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.element,
  label: PropTypes.string,
  onClick: PropTypes.func,
}

ApplyButton.defaultProps = {
  busy: false,
  disabled: false,
  icon: undefined,
  label: undefined,
  onClick: undefined,
}

export default ApplyButton
