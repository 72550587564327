import React from 'react'

import L10nFormattedMessage from '../../L10nFormattedMessage'

const Footer = () => (
  <footer className="app-footer">
    <span>
      &copy;
      {` 2017-${process.env.REACT_APP_COPYRIGHT_YEAR} `}
      <a href="http://avigilon.com">Avigilon Corporation.</a>
      {' '}
      <L10nFormattedMessage id="layout.es.footer.allrightsreserved" />
    </span>
  </footer>
)

export default Footer
