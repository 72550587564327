import * as types from '../constants/ActionTypes'

const cloud = (state = {
  address: '',
  claimStatus: {},
}, action) => {
  switch (action.type) {
    case types.CLOUD_CLAIM_URL:
      return { ...state, address: action.address }
    case types.CLOUD_CLAIM_STATUS:
      return { ...state, claimStatus: action.claimStatus }
    default:
      return state
  }
}

export default cloud
