import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

import reducer from './reducers'

import App from './App'

import { refresh } from './actions/user'
import Token from './utils/Token'

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
  reducer,
  undefined,
  composeEnhancers(applyMiddleware(thunk)),
)

// refresh token immediately
if (Token.get()) {
  store.dispatch(refresh(false))
}

const theme = createMuiTheme({
  palette: {
    primary: {
      dark: '#00467f', // $darker-blue
      light: '#3d6f99', // $light-blue
      main: '#005daa', // $avigilon-blue
    },
  },
})

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <App dispatch={store.dispatch} />
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
)
