import * as types from '../constants/ActionTypes'
import Token from '../utils/Token'

const user = (state = {
  initialized: undefined,
  loginFailed: false,
  token: Token.get(),
}, action) => {
  switch (action.type) {
    case types.USER_INITIALIZED:
      return { ...state, initialized: action.initialized }
    case types.USER_NEW_TOKEN:
      return { ...state, loginFailed: false, token: action.token }
    case types.USER_LOGIN_FAILURE:
      return { ...state, loginFailed: true, token: undefined }
    case types.USER_LOGOUT:
      return { ...state, loginFailed: false, token: undefined }
    default:
      return state
  }
}

export default user
