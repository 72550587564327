import * as types from '../constants/ActionTypes'

const webadmin = (state = {
  enablePing: true,
  lastPing: undefined,
  lastPingFailed: false,
}, action) => {
  switch (action.type) {
    case types.WEBADMIN_PING:
      return { ...state, lastPing: action.lastPing, lastPingFailed: false }
    case types.WEBADMIN_PING_FAILED:
      return { ...state, lastPingFailed: true }
    default:
      return state
  }
}

export default webadmin
