import { merge } from 'lodash'

import * as types from '../constants/ActionTypes'

const VALID_ID_CHARS = /[^A-Za-z0-9_-]/g

const network = (state = {
  connections: {},
  connectionsHwinfo: {},
  connectionsRoutes: {},
  dns: {
    automatic: true,
    servers: [],
  },
  poe: {
    ports: [],
  },
  switch: {
    ports: [],
  },
}, action) => {
  switch (action.type) {
    case types.NETWORK_CONNECTION:
      return {
        ...state,
        connections: Object.fromEntries(Object.entries(action.connections).map(([id, conn]) => ([
          id,
          {
            ...conn,
            safeID: id.replace(VALID_ID_CHARS, '_'),
          },
        ]))),
      }
    case types.NETWORK_DNS:
      return { ...state, dns: action.dns }
    case types.NETWORK_HWINFO:
      return { ...state, connectionsHwinfo: merge(state.connectionsHwinfo, action.hwinfo) }
    case types.NETWORK_IDENTIFY:
      return {
        ...state,
        connections: Object.keys(state.connections).reduce((acc, cur) => {
          const con = state.connections[cur]
          if (cur === action.name) {
            acc[cur] = { ...con, isBlinking: action.isBlinking }
          } else {
            // we should have only one ethernet port blinking
            acc[cur] = { ...con, isBlinking: false }
          }
          return acc
        }, {}),
      }
    case types.NETWORK_POE:
      return { ...state, poe: action.poe }
    case types.NETWORK_ROUTES:
      return { ...state, connectionsRoutes: merge(state.connectionsRoutes, action.routes) }
    case types.NETWORK_SWITCH:
      return { ...state, switch: action.switch }
    default:
      return state
  }
}

export default network
