import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'

const PrivateRoute = ({
  component: Component,
  dispatch,
  location,
  token,
}) => (
  <Route
    render={() => (
      token ? (
        <Component dispatch={dispatch} location={location} />
      ) : (
        <Redirect
          to={{ pathname: '/login', state: { from: location } }}
        />
      )
    )}
  />
)

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.node]).isRequired,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  token: PropTypes.string,
}

PrivateRoute.defaultProps = {
  token: undefined,
}

const mapStateToProps = (state) => ({
  token: state.user.token,
})

export default connect(mapStateToProps, null)(PrivateRoute)
