export const nav = (poe, storagelessDevice) => ([
  {
    'automation-id': 'navlink-sidebar-dashboard',
    icon: 'tachometer-alt',
    message: 'layout.es.sidebar.dashboard',
    url: '/dashboard',
  },
  {
    title: true,
    message: 'common.services',
  },
  {
    'automation-id': 'dropdown-sidebar-services-acc',
    icon: 'eye',
    message: 'ACC',
    url: '/acc',
    children: [
      {
        'automation-id': 'navlink-sidebar-services-acc-server',
        message: 'common.server',
        url: '/acc/server',
      },
      {
        'automation-id': 'navlink-sidebar-services-acc-logs',
        message: 'layout.es.sidebar.logs',
        url: '/acc/logs',
      },
    ],
  },
  {
    title: true,
    message: 'common.system',
  },
  {
    'automation-id': 'navlink-sidebar-system-device',
    icon: 'cogs',
    message: 'common.device',
    url: '/device',
  },
  {
    'automation-id': 'navlink-sidebar-system-network',
    icon: 'globe',
    message: 'common.network',
    url: '/network',
  },
  ...(poe ? [
    {
      'automation-id': 'navlink-sidebar-system-poe',
      icon: 'bolt',
      message: 'common.poe',
      url: '/poe',
    },
  ] : []),
  ...(!storagelessDevice ? [{
    'automation-id': 'dropdown-sidebar-storage',
    icon: 'database',
    message: 'common.storage',
    url: '/storage',
  }] : []),
  {
    'automation-id': 'dropdown-sidebar-system-logs',
    icon: 'book',
    message: 'layout.es.sidebar.logs',
    url: '/logs/device',
  },
  {
    'automation-id': 'navlink-sidebar-system-about',
    icon: 'info-circle',
    message: 'common.about',
    url: '/about',
  },
])

export const navBlueConnect = (poe, storagelessDevice) => ([
  {
    'automation-id': 'navlink-sidebar-dashboard',
    icon: 'tachometer-alt',
    message: 'layout.es.sidebar.dashboard',
    url: '/dashboard',
  },
  {
    title: true,
    message: 'common.system',
  },
  {
    'automation-id': 'navlink-sidebar-system-device',
    icon: 'cogs',
    message: 'common.device',
    url: '/device',
  },
  {
    'automation-id': 'navlink-sidebar-system-network',
    icon: 'globe',
    message: 'common.network',
    url: '/network',
  },
  ...(poe ? [
    {
      'automation-id': 'navlink-sidebar-system-poe',
      icon: 'bolt',
      message: 'common.poe',
      url: '/poe',
    },
  ] : []),
  ...(!storagelessDevice ? [{
    'automation-id': 'dropdown-sidebar-storage',
    icon: 'database',
    message: 'common.storage',
    url: '/storage',
  }] : []),
  {
    'automation-id': 'dropdown-sidebar-system-logs',
    icon: 'book',
    message: 'layout.es.sidebar.logs',
    url: '/logs',
    children: [
      {
        'automation-id': 'navlink-sidebar-system-logs-services',
        message: 'common.services',
        url: '/logs/services',
      },
      {
        'automation-id': 'navlink-sidebar-system-logs-device',
        message: 'common.device',
        url: '/logs/device',
      },
    ],
  },
  {
    'automation-id': 'navlink-sidebar-system-about',
    icon: 'info-circle',
    message: 'common.about',
    url: '/about',
  },
])
