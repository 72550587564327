import PropTypes from 'prop-types'
import React from 'react'
import { useIntl } from 'react-intl'

const L10nFormattedMessage = ({ defaultMessage, id, values }) => {
  const intl = useIntl()

  const message = intl.formatMessage({ id, defaultMessage }, values)

  /* eslint-disable react/no-danger */
  return (
    <span
      dangerouslySetInnerHTML={{ __html: `<!-- l10n key: ${id} -->${message}` }}
    />
  )
  /* eslint-enable react/no-danger */
}

L10nFormattedMessage.propTypes = {
  defaultMessage: PropTypes.string,
  id: PropTypes.string.isRequired,
  values: PropTypes.object,
}

L10nFormattedMessage.defaultProps = {
  defaultMessage: undefined,
  values: undefined,
}

export default L10nFormattedMessage
