import PropTypes from 'prop-types'
import React from 'react'
import {
  Button,
  Col,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Spinner,
} from 'reactstrap'
import { Field, reduxForm } from 'redux-form'

import L10nFormattedMessage from '../../L10nFormattedMessage'

const LoginForm = ({
  handleSubmit,
  pristine,
  submitting,
}) => (
  <Form onSubmit={handleSubmit}>
    <FormGroup row>
      <Col xs={12}>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="fas fa-fw fa-user" />
            </InputGroupText>
          </InputGroupAddon>
          <Field
            name="username"
            component="input"
            type="text"
            className="form-control"
            automation-id="input-form-login-username"
            required
          />
        </InputGroup>
      </Col>
    </FormGroup>
    <FormGroup row>
      <Col xs={12}>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="fas fa-fw fa-lock" />
            </InputGroupText>
          </InputGroupAddon>
          <Field
            name="password"
            component="input"
            type="password"
            className="form-control"
            automation-id="input-form-login-password"
          />
        </InputGroup>
      </Col>
    </FormGroup>
    <Button
      color="info"
      type="submit"
      disabled={pristine || submitting}
      automation-id="button-form-login-submit"
    >
      {submitting ? (
        <Spinner color="light" size="sm" />
      ) : (
        <i className="fas fa-sign-in-alt" />
      )}
      {' '}
      <L10nFormattedMessage id="login.login" />
    </Button>
  </Form>
)

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
}

export default reduxForm({
  form: 'login',
})(LoginForm)
