import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from 'reactstrap'
import { Redirect } from 'react-router-dom'

import { fetchInitialized, firstTimeInit, login } from '../../actions/user'
import { fetchInfo } from '../../actions/system'
import CenterSpinner from '../../components/CenterSpinner'
import L10nFormattedMessage from '../../components/L10nFormattedMessage'
import LoginForm from '../../components/Form/LoginForm'
import PasswordForm from '../../components/Form/PasswordForm'

import Logo from '../../assets/img/logo.svg'
import LogoBlueConnect from '../../assets/img/logo-blue-connect.svg'

class Login extends Component {
  constructor(props) {
    super(props)

    props.dispatch(fetchInitialized())

    const intervalId = setInterval(() => {
      props.dispatch(fetchInfo())
      props.dispatch(fetchInitialized())
    }, 3000)

    this.state = {
      intervalId,
    }
  }

  handleSubmit = (values) => {
    const { dispatch, user } = this.props

    if (user.initialized) {
      const { username, password } = values
      return dispatch(login(username, password))
    }

    const { newPassword } = values
    return dispatch(firstTimeInit(newPassword))
  }

  render() {
    const { location, user, system } = this.props

    if (!isEmpty(system.info) && user.initialized !== undefined) {
      const { intervalId } = this.state
      clearInterval(intervalId)

      if (user.token) {
        const { from } = location.state || { from: { pathname: '/' } }

        return (
          <Redirect to={from} />
        )
      }

      return (
        <div className="app flex-row align-items-center">
          <Container>
            <Row className="justify-content-center">
              <Col md={6}>
                <Card className="p-4">
                  <CardBody>
                    <img alt="Avigilon" src={system.info.cloudDevice ? LogoBlueConnect : Logo} width="240px" />
                    {user.initialized ? (
                      <div>
                        <p className="text-muted mt-2">
                          <L10nFormattedMessage id="login.signinto" />
                          {` ${system.info.productName} `}
                          <span automation-id="span-login-serialnumber">
                            {system.info.serialNumber}
                          </span>
                        </p>
                        {user.loginFailed
                          && (
                          <Alert
                            color="warning"
                            automation-id="alert-login-warning"
                          >
                            <i className="fas fa-exclamation-triangle" />
                            {' '}
                            <L10nFormattedMessage id="login.loginfailure" />
                          </Alert>
                          )}
                        <LoginForm onSubmit={this.handleSubmit} />
                      </div>
                    ) : (
                      <div>
                        <p className="text-muted mt-2">
                          <L10nFormattedMessage id="login.createnewpassword" />
                        </p>
                        <PasswordForm firstTimeInit onSubmit={this.handleSubmit} />
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )
    }

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <CenterSpinner />
        </Container>
      </div>
    )
  }
}

Login.propTypes = {
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  system: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  user: state.user,
  system: state.system,
})

export default connect(mapStateToProps, null)(Login)
