import { now } from 'lodash'

import * as types from '../constants/ActionTypes'

export const ping = () => (dispatch) => (
  fetch('/api/system/ping')
    .then((resp) => {
      if (resp.ok) {
        return dispatch({
          type: types.WEBADMIN_PING,
          lastPing: now(),
        })
      }

      dispatch({ type: types.WEBADMIN_PING_FAILED })
      return Promise.resolve()
    })
    .catch((e) => {
      dispatch({ type: types.WEBADMIN_PING_FAILED })
      // eslint-disable-next-line no-console
      console.log(e && e.message ? e.message : 'ping failed')
    })
)

export default ping
