// user
export const USER_INITIALIZED = 'USER_INITIALIZED'
export const USER_NEW_TOKEN = 'USER_NEW_TOKEN'
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE'
export const USER_LOGOUT = 'USER_LOGOUT'

// acc
export const ACC_SERVER_INFO = 'ACC_SERVER_INFO'
export const ACC_SERVER_LICENSE = 'ACC_SERVER_LICENSE'
export const ACC_SERVER_LOG_TYPES = 'ACC_SERVER_LOG_TYPES'
export const ACC_SERVER_PORTS = 'ACC_SERVER_PORTS'
export const ACC_SERVER_STATUS = 'ACC_SERVER_STATUS'
export const ACC_SERVER_STORAGE_MANAGEMENT = 'ACC_SERVER_STORAGE_MANAGEMENT'
export const ACC_SERVER_REPAIRING = 'ACC_SERVER_REPAIRING'
export const ACC_SERVER_LPR = 'ACC_SERVER_LPR'

// blue/cloud
export const CLOUD_CLAIM_URL = 'CLOUD_CLAIM_URL'
export const CLOUD_CLAIM_STATUS = 'CLOUD_CLAIM_STATUS'

export const CERTIFICATES = 'CERTIFICATES'
export const CERTIFICATE_DETAILS = 'CERTIFICATE_DETAILS'
export const CERTIFICATES_DEFAULT_DISABLE = 'CERTIFICATES_DEFAULT_DISABLE'

// network
export const NETWORK_CONNECTION = 'NETWORK_CONNECTION'
export const NETWORK_DNS = 'NETWORK_DNS'
export const NETWORK_HWINFO = 'NETWORK_HWINFO'
export const NETWORK_IDENTIFY = 'NETWORK_IDENTIFY'
export const NETWORK_POE = 'NETWORK_POE'
export const NETWORK_ROUTES = 'NETWORK_ROUTES'
export const NETWORK_SWITCH = 'NETWORK_SWITCH'

// storage
export const STORAGE_PHYSICAL_DISKS = 'STORAGE_PHYSICAL_DISKS'
export const STORAGE_VIRTUAL_DISKS = 'STORAGE_VIRTUAL_DISKS'
export const STORAGE_DISK_BUSY = 'STORAGE_DISK_BUSY'
export const STORAGE_DISK_IDLE = 'STORAGE_DISK_IDLE'
export const STORAGE_DISK_IDENTIFY = 'STORAGE_DISK_IDENTIFY'

// system
export const SYSTEM_ACCELERATORS = 'SYSTEM_ACCELERATORS'
export const SYSTEM_INFO = 'SYSTEM_INFO'
export const SYSTEM_LANGUAGE = 'SYSTEM_LANGUAGE'
export const SYSTEM_PACKAGES = 'SYSTEM_PACKAGES'
export const SYSTEM_SERVICES = 'SYSTEM_SERVICES'
export const SYSTEM_SERVICES_BUSY = 'SYSTEM_SERVICES_BUSY'
export const SYSTEM_SSH = 'SYSTEM_SSH'
export const SYSTEM_TIME = 'SYSTEM_TIME'
export const SYSTEM_TIMEZONES = 'SYSTEM_TIMEZONES'
export const SYSTEM_UPGRADE = 'SYSTEM_UPGRADE'

// webadmin
export const WEBADMIN_PING = 'WEBADMIN_PING'
export const WEBADMIN_PING_FAILED = 'WEBADMIN_PING_FAILED'
