import cs from './cs.json'
import da from './da.json'
import deDE from './de-DE.json'
import enUS from './en-US.json'
import esES from './es-ES.json'
import fi from './fi.json'
import frFR from './fr-FR.json'
import hu from './hu.json'
import itIT from './it-IT.json'
import jaJP from './ja-JP.json'
import koKR from './ko-KR.json'
import nl from './nl.json'
import no from './no.json'
import pl from './pl.json'
import ptBR from './pt-BR.json'
import ptPT from './pt-PT.json'
import ruRU from './ru-RU.json'
import sk from './sk.json'
import sv from './sv.json'

// https://github.com/yahoo/react-intl/wiki/Upgrade-Guide
function flattenMessages(nestedMessages, prefix = '') {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      // eslint-disable-next-line no-param-reassign
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
}

const messages = {
  cs: flattenMessages(cs),
  da: flattenMessages(da),
  'de-DE': flattenMessages(deDE),
  'en-US': flattenMessages(enUS),
  'es-ES': flattenMessages(esES),
  fi: flattenMessages(fi),
  'fr-FR': flattenMessages(frFR),
  hu: flattenMessages(hu),
  'it-IT': flattenMessages(itIT),
  'ja-JP': flattenMessages(jaJP),
  'ko-KR': flattenMessages(koKR),
  nl: flattenMessages(nl),
  no: flattenMessages(no),
  pl: flattenMessages(pl),
  'pt-BR': flattenMessages(ptBR),
  'pt-PT': flattenMessages(ptPT),
  'ru-RU': flattenMessages(ruRU),
  sk: flattenMessages(sk),
  sv: flattenMessages(sv),
}

export default messages
