import { isEmpty, startsWith } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { Nav, NavItem } from 'reactstrap'

import L10nFormattedMessage from '../../L10nFormattedMessage'

import { nav, navBlueConnect } from './nav'

class Sidebar extends Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { location } = this.props
    if (nextProps.location !== location) {
      document.body.classList.remove('sidebar-mobile-show')
    }
  }

  activeRoute = (routeName) => {
    const { location: { pathname } } = this.props
    return startsWith(pathname, routeName)
      ? 'nav-item nav-dropdown open'
      : 'nav-item nav-dropdown'
  }

  handleClick = (event) => {
    event.preventDefault()
    let { target } = event
    // search for the correct element to toggle
    while (target && !target.classList.contains('nav-dropdown')) {
      target = target.parentElement
    }
    if (target) {
      target.classList.toggle('open')
    }
  }

  navDropdown = (item, index) => (
    <li key={index} className={this.activeRoute(item.url)}>
      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      <a
        className="nav-link nav-dropdown-toggle"
        href="#"
        automation-id={item['automation-id']}
        onClick={this.handleClick}
      >
        <i className={`fas fa-${item.icon} fa-fw`} />
        {' '}
        <L10nFormattedMessage
          id={item.message}
          defaultMessage={item.message}
        />
      </a>
      {/* eslint-enable jsx-a11y/anchor-is-valid */}
      <ul className="nav-dropdown-items">
        {this.navList(item.children)}
      </ul>
    </li>
  )

  navItem = (item, index) => (
    <NavItem key={index}>
      <NavLink
        className="nav-link"
        to={item.url}
        activeClassName="active"
        automation-id={item['automation-id']}
      >
        <i className={`fas fa-${item.icon} fa-fw`} />
        {' '}
        <L10nFormattedMessage
          id={item.message}
          defaultMessage={item.message}
        />
      </NavLink>
    </NavItem>
  )

  navLink = (item, index) => (
    item.title
      ? this.navTitle(item, index)
      : item.children
        ? this.navDropdown(item, index)
        : this.navItem(item, index)
  )

  navList = (items) => items.map((item, index) => this.navLink(item, index))

  navTitle = (item, index) => (
    <li key={index} className="nav-title">
      <L10nFormattedMessage
        id={item.message}
        defaultMessage={item.message}
      />
    </li>
  )

  render() {
    const { network, system } = this.props
    const poe = network.poe && !isEmpty(network.poe.ports)

    return (
      <div className="sidebar">
        <nav className="sidebar-nav" automation-id="nav-sidebar">
          <Nav>
            {this.navList(system.info.cloudDevice
              ? navBlueConnect(poe, system.info.storagelessDevice)
              : nav(poe, system.info.storagelessDevice))}
          </Nav>
        </nav>
      </div>
    )
  }
}

Sidebar.propTypes = {
  location: PropTypes.object.isRequired,
  network: PropTypes.object.isRequired,
  system: PropTypes.object.isRequired,
}

export default Sidebar
