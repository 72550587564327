import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  Col,
  Form,
  FormGroup,
  Label,
  Progress,
} from 'reactstrap'
import { Field, reduxForm } from 'redux-form'
import zxcvbn from 'zxcvbn'

import ApplyButton from '../../Button/ApplyButton'

import L10nFormattedMessage from '../../L10nFormattedMessage'

export const validate = (values) => {
  const errors = {}

  if (!values.password) {
    errors.password = 'Old password can not be empty'
  }

  if (values.newPassword) {
    const result = zxcvbn(values.newPassword)
    if (values.password === values.newPassword) {
      errors.newPassword = 'New password can not match old password'
    }

    if (result.score < 2) {
      errors.newPassword = result.feedback
    }
  } else {
    errors.newPassword = 'New password can not be empty'
  }

  if (values.confirmPassword) {
    if (values.newPassword !== values.confirmPassword) {
      errors.confirmPassword = 'New password is not confirmed'
    }
  } else {
    errors.confirmPassword = 'Confirm password can not be empty'
  }

  return errors
}

class PasswordForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      result: undefined,
    }
  }

  handleChange = (event, newValue) => {
    const { name } = event.target

    if (name === 'newPassword') {
      this.setState({
        result: newValue ? zxcvbn(newValue) : undefined,
      })
    }
  }

  render() {
    const {
      firstTimeInit,
      handleSubmit,
      pristine,
      submitting,
      valid,
      busy,
    } = this.props
    const { result } = this.state

    let value = 0
    let color = 'danger'
    if (result) {
      value = (result.score + 1) * 20
      if (result.score === 0 || result.score === 1) {
        color = 'danger'
      } else if (result.score === 2) {
        color = 'warning'
      } else {
        color = 'success'
      }
    }

    return (
      <Form onSubmit={handleSubmit}>
        {!firstTimeInit
          && (
          <FormGroup row>
            <Label md={2}>
              <L10nFormattedMessage id="device.password.old" />
            </Label>
            <Col xs={12} md={4}>
              <Field
                name="password"
                component="input"
                type="password"
                className="form-control"
                autoComplete="off"
                required
                onChange={this.handleChange}
                automation-id="input-form-password-oldpassword"
              />
            </Col>
          </FormGroup>
          )}
        <FormGroup row>
          <Label md={firstTimeInit ? 4 : 2}>
            <L10nFormattedMessage id="device.password.new" />
          </Label>
          <Col xs={12} md={firstTimeInit ? 8 : 4}>
            <Field
              name="newPassword"
              component="input"
              type="password"
              className="form-control"
              autoComplete="new-password"
              required
              onChange={this.handleChange}
              automation-id="input-form-password-newpassword"
            />
          </Col>
          <Label md={firstTimeInit ? 4 : 2}>
            <L10nFormattedMessage id="device.password.confirm" />
          </Label>
          <Col xs={12} md={firstTimeInit ? 8 : 4}>
            <Field
              name="confirmPassword"
              component="input"
              type="password"
              className="form-control"
              autoComplete="off"
              required
              onChange={this.handleChange}
              automation-id="input-form-password-confirmpassword"
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md={firstTimeInit ? 4 : 2}>
            <Label>
              <L10nFormattedMessage id="device.password.strength" />
            </Label>
          </Col>
          <Col xs={12} md={firstTimeInit ? 8 : 4}>
            <Progress color={color} value={value} automation-id="div-form-password-strength" />
          </Col>
        </FormGroup>
        <ApplyButton
          automation-id="button-form-password-apply"
          busy={busy || submitting}
          disabled={pristine || submitting || !valid}
        />
      </Form>
    )
  }
}

PasswordForm.defaultProps = {
  firstTimeInit: false,
  busy: false,
}

PasswordForm.propTypes = {
  firstTimeInit: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  busy: PropTypes.bool,
}

export default reduxForm({
  form: 'password',
  validate,
})(PasswordForm)
